import React from "react";
import html from '../CCS/img/html.png';
import inte from '../CCS/img/universidad.png';
import img1 from '../img/tecnologi.jpg';
import img2 from '../img/biblioteca.jpg';
import { Link } from "react-router-dom";

class Conocimientos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            conocimiento1: ""
        }
    }

    componentDidMount() {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ funcion: '9' }) // Convertir el objeto a una cadena JSON
        };
        fetch('https://fedaniky.com/API/api.php', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ conoci: this.setState({ conocimiento1: data }) }))
            //.then(data => console.log( data))
            .catch(error => console.error('Error:', error));
    }


    render() {
        let info = this.state.conocimiento1;
        let info1 = this.state.conocimiento1['etiquetaetique'];
        let info2 = this.state.conocimiento1['etiquetas'];
        let info3 = this.state.conocimiento1['etiquetanumero'];
        let info4 = this.state.conocimiento1['etiquetanombre'];
        let info5 = this.state.conocimiento1['idetiquetas'];
        let arraynombres = [];
        let arraynomvreet = [];
        let arrayconocimi = [];
        let html1 = "";
        let url, arrayimprimir, idcadauno, s;
        s = 0;
        if (info1 != undefined) {
            arrayimprimir = info2;
            arraynomvreet = info5;
            arrayconocimi = info1;

            info4.map((e) => {
                arraynombres.push(e);
            });




        } else {
            arrayimprimir = ['SIS'];
        }


        return (
            <div>

                <div className="tituloconoprin"><h1 style={{ fontSize: '80px', color: '#1ba0f2' }}>Mis <span style={{ color: '#f2a516' }}>Conocimietos</span></h1></div>

                <div>
                    <div className="circuloconverini"></div>
                    <svg viewBox="0 0 800 300" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M 0 300 Q 250 200 400 250 Q 800 320 850 40 " fill="none" stroke="#1ba0f2" stroke-width="60" />
                        <path d="M 0 400 Q 250 200 420 250 Q 800 320 850 100 " fill="none" stroke="#f2a516" stroke-width="60" />
                    </svg>
                </div>


                <br /><br />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <Link to={'/Proyectos'} >
                                <div className="contenedorconoati">
                                    <img src={img1} />
                                    <svg viewBox="0 0 800 300" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-250px', position: 'relative' }} >
                                        <path d="M 0 400 Q 250 200 420 250 Q 900 320 900 100 " fill="none" stroke="#f2a516" stroke-width="180" />
                                    </svg>
                                    <h3 className="titulosconoenci">Proyectos</h3>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-6">
                            <Link to={'/Titulos'} >
                                <div className="contenedorconoati">
                                    <img src={img2} />
                                    <svg viewBox="0 0 800 300" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-250px', position: 'relative' }} >
                                        <path d="M 0 400 Q 250 200 420 250 Q 900 320 900 100 " fill="none" stroke="#1ba0f2" stroke-width="180" />
                                    </svg>
                                    <h3 className="titulosconoenci">Titulos</h3>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>


                <div className="container" id="imprimir1">
                    <div className="row">
                        <div className="col-lg-12" style={{ display: 'contents' }} >
                            {
                                arrayimprimir.map((e) => {
                                    let nombreeti = arraynomvreet[s];
                                    let m = 0;
                                    let html5 = [];
                                    html5.push(<h2 className="titulocon1">{nombreeti}</h2>)
                                    s++;
                                    arrayconocimi.map((a) => {
                                        if (e == a) {
                                            let nombre = arraynombres[m];
                                            url = "https://fedaniky.com/img_con/" + (nombre) + ".png";
                                            html5.push(
                                                <div className="logosconoci">
                                                    <img src={url} alt="fedanikyconocimientos" className="adaprarlogocon" />
                                                    <h3 className="nombrecon">{nombre}</h3>
                                                </div>
                                            )
                                        }
                                        m++;
                                    })
                                    html5.push(<div style={{ marginTop: '40px' }}></div>)
                                    return (html5);
                                })
                            }
                        </div>
                    </div>
                </div>





            </div >
        );
    };

}

export default Conocimientos;