import React from "react";
import html from '../CCS/img/html.png';
import inte from '../CCS/img/universidad.png';
import inic from '../img/icono/inicio-nav.png';
import proy from '../img/icono/proyecto.png';
import cono from '../img/icono/pens.png';
import cont from '../img/icono/directorio-telefonico.png';
import logo from '../img/icono/fedaniky.png';
import { Link } from "react-router-dom";

class Nadvar extends React.Component {
    cargacolocar(event) {
        let v = document.getElementById("punto1");
        let p1 = parseInt(event.clientX);
        let p2 = parseInt(event.clientY);
        v.style.display = "block";
        v.style.marginLeft = p1 + "px";
        v.style.marginTop = p2 + "px";
        v.style.animationName = 'ampliar';
        v.style.animationDuration = "1s";
        setTimeout(() => {
            v.removeAttribute('style');
            v.style.display = "none";
        }, 1000);
    }

    render() {
        return (
            <div>
                <div className="puntoconentn" id="punto1">
                </div>
                <div className="nadvar-inic">
                    <div className="ccontnad2">
                        <div className="contend-text-nadv">
                            <Link to={'./Inicio'} onClick={this.cargacolocar} id="nadv1"><p><span><img src={inic} className="logo_nav" /></span> INICIO</p></Link>
                        </div>
                        <div className="contend-text-nadv">
                            <Link to={'./Proyectos'} onClick={this.cargacolocar} id="nadv1"><p><span><img src={proy} className="logo_nav" /></span>PROYECTOS</p></Link>
                        </div>
                    </div>
                    <div className="ccontnad1">
                        <div className="cirvulo-nadvar">
                            <img src={logo} className="logonadvar" />
                        </div>
                    </div>
                    <div className="ccontnad2">
                        <div className="contend-text-nadv2">
                            <Link to={'./Conocimientos'} onClick={this.cargacolocar} id="nadv1"><p><span><img src={cono} className="logo_nav" /></span>HABILIDADES</p></Link>
                        </div>
                        <div className="contend-text-nadv2">
                        <Link to={'./Contactanos'} onClick={this.cargacolocar} id="nadv1">
                            <p><span><img src={cont} className="logo_nav" /></span>CONTACTO</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Nadvar;