import React from "react";
import vid from '../img/video/contacta.mp4';
import liks from '../img/icono/linkedin.png';
import { Link } from "react-router-dom";
import Nadvar from "./Nadvar";

class Contactanos extends React.Component {


    render() {
        return (
            <div>
                <div>
                    <video className="videofondocontact" src={vid} autoplay="true" muted="true" loop="true" poster={vid}></video>
                </div>

                <div className="container montarcontac">
                    <div className="row">
                        <div className="col-xl-12">
                            <h1 className="titulocontac1">CONTACTAME</h1>
                            <h3 className="contactext1">Es un gusto poder hablar contigo.</h3>
                            <input type="correo" class="form-control inptucontact" placeholder="Correo Electronico" aria-label="Correo Electronico" aria-describedby="addon-wrapping" />
                            <p style={{ textAlign: 'center' }}>
                                <button className="centrar button1">ENVIAR</button>
                            </p>

                            <h4 className="contactext1">Redes Sociales</h4>
                            <p style={{ textAlign: 'center' }}>
                                <a href="https://www.linkedin.com/in/daniel-felipe-fonseca-narvaez-127526203/" target="_blank">
                                    <img src={liks} alt="likedingdaniel" className="iconoscontac" />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}

export default Contactanos;