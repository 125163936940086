import React from "react";
import video1 from "../img/video/fondo1.mp4";
import yo from "../CCS/img/yo.jpg"
import { Link } from "react-router-dom";

class Inicio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conocimiento1: ""
        }
    }

    componentDidMount() {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ funcion: '16' }) // Convertir el objeto a una cadena JSON
        };
        fetch('https://fedaniky.com/API/api.php', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ conoci: this.setState({ conocimiento1: data }) }))
            //.then(data => console.log( data))
            .catch(error => console.error('Error:', error));
    }

    cargacolocar(event) {
        let v = document.getElementById("punto1");
        let p1 = parseInt(event.clientX);
        let p2 = parseInt(event.clientY);
        v.style.display = "block";
        v.style.marginLeft = p1 + "px";
        v.style.marginTop = p2 + "px";
        v.style.animationName = 'ampliar';
        v.style.animationDuration = "1s";
        setTimeout(() => {
            v.removeAttribute('style');
            v.style.display = "none";
        }, 1000);

    }

    render() {

        let cont = this.state.conocimiento1;

        console.log(cont);

                return (
            <div>
                <div>
                    <video className="videofondo" src={video1} autoplay="true" muted="true" loop="true" poster={video1}></video>
                </div>
                <div class="container-fluid inicib">
                    <div class="row">
                        <div class="col-lg-1 "></div>
                        <div class="col-lg-5 ">
                            <div class="dialogo1">
                                <h1 class="titulo1">HOLA A TODOS</h1>
                                <p className="centrartexin">Bienvenido a mi porfolio. aqui pódras encontrar un poco de mis conocimientos como experiencias</p>
                            </div>
                        </div>
                        <div class="col-lg-5 ">
                            <p class="centrart">
                                <img src={yo} class="imginicio2" alt="DANIE FONSECA" />
                            </p>
                        </div>
                        <div class="col-lg-1 "></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-5">
                            <p class="centrart">
                                <img src="https://media.giphy.com/media/8m4gPv1UFz1jmiCtKd/giphy.gif" class="imginicio" />
                            </p>
                        </div>
                        <div class="col-lg-5 centrart"><br />
                            <h2 class="titulo2">Mis Proyectos</h2>
                            <h3>Total de proyectos: </h3>
                            <h2>4</h2>
                            <Link to={'Proyectos'}><button type="button" class="button1" onClick={this.cargacolocar}>VER</button></Link>
                        </div>
                      
                        <div class="col-lg-1"></div>
                    </div>

                    <div class="row contani1">
                        <div class="col-lg-1 "></div>

                      
                        <div class="col-lg-5 ">
                            <h1 class="titulo1">Mis Conocimientos</h1>
                            <h3 style={{ color: 'white' }}>Tengo Una variedad de habilidades , te invito a verlas.</h3>
                            <Link to={'./Conocimientos'}><button type="button" class="button2" onClick={this.cargacolocar}>VER</button></Link>
                        </div>

                        <div class="col-lg-5 ">
                            <p class="centrart">
                                <img src="https://media.giphy.com/media/iwCNOcCDJvxSDLxAvm/giphy.gif" class="imginicio" />
                            </p>
                        </div>
                        <div class="col-lg-1 "></div>
                    </div>
                </div>



            </div>
        );
    };

}

export default Inicio;