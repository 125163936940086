import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import Conocimientos from './Modals/conocimientos';
import Inicio from './Modals/Inicio';
import Proyectos from './Modals/Proyectos';
import Nadvar from './Modals/Nadvar';
import Footer from './Modals/footer';
import Titulo from './Modals/Titulos';
import Contactanos from './Modals/Contactanos';

function App() {
  return (
    <div className="App">
      <Router>
        <Nadvar />
        <Routes>
          <Route path="/Conocimientos" element={<Conocimientos />} />
          <Route path="/Titulos" element={<Titulo />} />
          <Route path="/Inicio" element={<Inicio />} />
          <Route path="/Proyectos" element={<Proyectos />} />
          <Route path="/Contactanos" element={<Contactanos />} />
          <Route path="/*" element={<Inicio />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
