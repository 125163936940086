import React from "react";
import Swal from "sweetalert2";


class Titulo extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            etiquetas: "",
            etiesd: "",
            imgdiplo: "",
            cantinf: "",
            cantidf: ""
        }
    }



    componentDidMount() {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ funcion: '11' }) // Convertir el objeto a una cadena JSON
        };
        fetch('https://fedaniky.com/API/api.php', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ etiquetast: this.setState({ etiquetas: data }) }))
            //.then(data => console.log(data))
            .catch(error => console.error('Error:', error));

        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ funcion: '8' }) // Convertir el objeto a una cadena JSON
        };
        fetch('https://fedaniky.com/API/api.php', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ etiquetast2: this.setState({ etiesd: data }) }))
            //.then(data => console.log(data))
            .catch(error => console.error('Error:', error));

        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ funcion: '13' }) // Convertir el objeto a una cadena JSON
        };
        fetch('https://fedaniky.com/API/api.php', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ img: this.setState({ imgdiplo: data }) }))
            //.then(data => console.log(data))
            .catch(error => console.error('Error:', error));

        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ funcion: '17' }) // Convertir el objeto a una cadena JSON
        };
        fetch('https://fedaniky.com/API/api.php', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ img: this.setState({ cantinf: data }) }))
            //.then(data => console.log(data))
            .catch(error => console.error('Error:', error));

        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ funcion: '18' }) // Convertir el objeto a una cadena JSON
        };
        fetch('https://fedaniky.com/API/api.php', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ img: this.setState({ cantidf: data }) }))
            //.then(data => console.log(data))
            .catch(error => console.error('Error:', error));

    }











    render() {
        let arrayenti, arrayetiq, arrayimg, link, arraynomb, sun1, arrayetmm, arrayidet, s,cantidadt;
        arrayenti = [''];
        arrayetiq = [''];
        arrayimg = [''];
        arraynomb = [''];
        arrayidet = [''];
        cantidadt = [''];
        sun1 = -1;
        s = -1;

        if (this.state.etiquetas['etiquetanombre'] != undefined) {
            arrayenti = this.state.etiquetas['etiquetanombre'];
            arrayidet = this.state.etiquetas['etiquetanumero'];
        }

        if (this.state.etiesd['etiquetanombre'] != undefined) {
            arrayetiq = this.state.etiesd['etiquetanombre'];
        }

        if (this.state.imgdiplo['etiquetanombre'] != undefined) {
            arrayimg = this.state.imgdiplo['etiquetanombre'];
            arraynomb = this.state.imgdiplo['etiquetacolocs'];
            arrayetmm = this.state.imgdiplo['etiquetaryiwut'];
            cantidadt = arrayimg.length;
        }

        function ampliarimg() {
            let checks = document.getElementsByClassName("check");
            let presis = [];
            let html = "";
            for (let i = 0; i < checks.length; i++) {
                const element = checks[i].checked;
                if (element == true) {
                    let classname = checks[i].className;
                    let extract = classname.split('-')
                    presis.push(extract[1]);
                }
            }
            if (presis.length == 0) {
                let s = 0;
                arrayimg.map((e) => {
                    let link = "https://fedaniky.com/diplomas/" + e + ".png";
                    let nomb = arraynomb[s];
                    html += '<div class="diplomasmotimg"><img src="' + link + '" /><h3>' + nomb + '</h3></div>';
                    s++;
                })
            } else {
                let s = 0;
                arrayetmm.map((e) => {
                    if (presis.indexOf(e) > -1) {
                        let link = "https://fedaniky.com/diplomas/" + arrayimg[s] + ".png";
                        let nomb = arraynomb[s];
                        html += '<div class="diplomasmotimg"><img src="' + link + '" /><h3>' + nomb + '</h3></div>';
                    }
                    s++;
                })
            }
            document.getElementById("imprx1").innerHTML = "";
            document.getElementById("imprx1").innerHTML = html;
        }


        function ampliarimagen(url) {
            console.log(url);
            const Swal = require('sweetalert2')
            Swal.fire({
                imageUrl: url,

            });
        }

        return (
            <div>



                <div class="alineartitulacion">
                    <div class="nadvarlateralti">

                        <h2 className="titulosacademias">Total Titulos</h2>
                        <h2 className="titulosacademias">{cantidadt}</h2>


                        <h2 className="titulosacademias">ACADEMIAS</h2>
                        <ol>
                            {
                                arrayenti.map((e) => {
                                    s++;
                                    let cantid = this.state.cantinf[e];
                                    let casstr = arrayidet[s];

                                    if (cantid == undefined) {
                                        cantid = 0;
                                    }
                                    return (<li><input type="checkbox" className={"check aca-" + casstr} onClick={ampliarimg} style={{ marginRight: '20px' }} />{e + " " + "(" + cantid + ")"}</li>)
                                    s++;
                                }
                                )}
                        </ol>
                        <h2 className="titulosacademias">CATEGORIAS</h2>
                        <ol>

                            {arrayetiq.map((e) => {
                                let cantid = this.state.cantidf[e];
                                if (cantid == undefined) {
                                    cantid = 0;
                                }
                                return (<li><input type="checkbox" style={{ marginRight: '20px' }} /> {e + " (" + cantid + ")"}</li>)
                            }
                            )}
                        </ol>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12" id="imprx1" style={{ display: 'contents' }}>


                                {
                                    arrayimg.map((e) => {
                                        sun1 += 1;
                                        link = "https://fedaniky.com/diplomas/" + e + ".png";
                                        return (
                                            <div class="diplomasmotimg">
                                                <img onClick={(e) => ampliarimagen(e.target.getAttribute("src"))}
                                                    src={link} />
                                                <h3>{arraynomb[sun1]}</h3>
                                            </div>)

                                    }
                                    )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}

export default Titulo;