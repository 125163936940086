import React from "react";
import { Link } from "react-router-dom";

class Proyectos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            etiquetas: ""
        }
    }

    componentDidMount() {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ funcion: '15' }) // Convertir el objeto a una cadena JSON
        };

        fetch('https://fedaniky.com/API/api.php', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ etiquetast: this.setState({ etiquetas: data }) }))
            //.then(data => console.log(data))
            .catch(error => console.error('Error:', error));

    }


    render() {

        let datos, datos2, i, img, link , ya;
        datos = this.state.etiquetas;
        i = -1;
        ya = "";

        if (datos == "") {
            datos2 = [""];

        } else {
            datos2 = datos['nombre'];
            ya = "si";

        }

        console.log(datos);

        return (
            <div>
            <div className="conteneprotar"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="tituproyectos">Proyectos</h1>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                datos2.map((e) => {
                                    i++;
                                    if(ya == ""){
                                    }else{
                                        img = "https://fedaniky.com/proyectos/" + datos['img'][i] + ".png";
                                    }
                                    link = datos['link'][i];
                                    return (
                                        <a href={link}>
                                            <div className="contenedorpro" target="_blank">
                                                <img src={img} className="contenedor-image-pro" />
                                                <div className="titulocontenpro">
                                                    <p>{e}</p>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                    i++;
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}

export default Proyectos;