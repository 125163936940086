import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {

    render() {
        return (
            <div>

            <div className="footer"></div>



            </div>
        );
    };

}

export default Footer;